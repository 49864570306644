import React, { Fragment } from "react";

import { Link } from "gatsby";
import { LogoWrapper } from "./style";

const Logo = (props) => {
  const contents = () => {
    return (
      <Fragment>
        <div className="wrapper original">
          <div className="bar left"></div>
          <div className="bar top"></div>
        </div>
        <div className="wrapper duplicate">
          <div className="bar left" id="right"></div>
          <div className="bar top" id="bottom"></div>
        </div>
        {props.logoClass === "basic" || "white" ? <span>{props.text}</span> : <h3>{props.text}</h3>}
      </Fragment>
    );
  };
  return (
    <LogoWrapper className="logo-wrapper">
      <div className="logo-inner">
        {props.linkTo ? (
          <Link to={props.linkTo} className={props.logoClass}>
            {contents()}
          </Link>
        ) : (
          <div className={`default ${props.logoClass}`}>{contents()}</div>
        )}
      </div>
    </LogoWrapper>
  );
};

export default Logo;
