import styled, { css } from "styled-components";

import { hoverTransition, hoverScaleDown, onHoverFocus } from "../Global/style-variables";

export const LogoWrapper = styled.div`
  max-width: 300px;

  .logo-inner {
    display: inline-flex;
    align-items: center;

    a,
    .default {
      ${hoverTransition};
      position: relative;
      display: flex;

      .wrapper {
        width: 130px;
        height: 100px;
        display: flex;
        position: absolute;

        .bar {
          background-color: ${(props) => props.theme.colors.mainPurple};
          opacity: 0.8;
          border-radius: 50px;
        }
        .left {
          background-color: ${(props) => props.theme.colors.mainPurple};
          opacity: 0.8;
          transform: translateX(10px);
          width: 10px;
          height: 55px;
          border-top-right-radius: 0;
        }
        .top {
          width: 55px;
          height: 10px;
          border-bottom-left-radius: 0;
          z-index: 1;
        }
      }
      .original {
        position: relative;
        width: fit-content;
        height: fit-content;
      }
      .duplicate {
        position: absolute;
        top: -16px;
        left: -6px;
        transform: rotate(180deg);
        height: fit-content;
        width: fit-content;

        #right,
        #bottom {
          background-color: ${(props) => props.theme.colors.logoPurpleLight};
          opacity: 0.8;
        }
      }
      span {
        color: ${(props) => props.theme.colors.mainPurple};
        font-size: 1.6rem;
        margin-left: -2px;
        align-self: center;
      }
    }
    a {
      ${onHoverFocus(() => {
        return css`
          ${hoverScaleDown};
          outline: none;
        `;
      })};
    }
  }

  //// Main Purple Logo Optimisation ////
  @media only screen and (max-width: 770px) {
    .logo-inner {
      a {
        .wrapper {
          .left {
            height: 50px;
          }
          .top {
            width: 50px;
          }
        }
        .duplicate {
          top: -14px;
          left: -4px;
        }
      }
    }
  }
  @media only screen and (max-width: 500px) {
    .logo-inner {
      a {
        .wrapper {
          .left {
            height: 45px;
            width: 8px;
            transform: translateX(8px);
          }
          .top {
            width: 50px;
            height: 8px;
          }
        }
        .duplicate {
          top: -13px;
          left: -6px;
        }
      }
    }
  }
  @media only screen and (max-width: 375px) {
    .logo-inner {
      a {
        .wrapper {
          .left {
            height: 40px;
            width: 6px;
            transform: translateX(6px);
          }
          .top {
            width: 40px;
            height: 6px;
          }
        }
      }
    }
  }

  //// Colored Logo Styling ////
  .logo-inner {
    .yellow {
      .wrapper {
        .bar {
          background-color: ${(props) => props.theme.colors.logoYellow};
        }
        .left {
          background-color: ${(props) => props.theme.colors.logoOrange};
          transform: translateX(8px);
          width: 8px;
          height: 45px;
        }
        .top {
          background-color: ${(props) => props.theme.colors.logoOrange};
          width: 45px;
          height: 8px;
          border-bottom-left-radius: 0;
        }
      }
      .duplicate {
        top: -12px;
        left: -4px;

        #right,
        #bottom {
          background-color: ${(props) => props.theme.colors.logoYellow};
          opacity: 0.8;
        }
      }
      h3 {
        color: ${(props) => props.theme.colors.logoYellow};
        font-family: ${(props) => props.theme.fonts.heading};
        line-height: 0;
        font-size: 1.8rem;
        margin-left: 2px;
        align-self: center;
      }
    }
    .blue {
      .wrapper {
        .bar {
          background-color: ${(props) => props.theme.colors.logoBlue};
        }
        .left {
          background-color: ${(props) => props.theme.colors.logoBlue};
          transform: translateX(8px);
          width: 8px;
          height: 45px;
        }
        .top {
          background-color: ${(props) => props.theme.colors.logoBlue};
          width: 45px;
          height: 8px;
          border-bottom-left-radius: 0;
        }
      }
      .duplicate {
        top: -12px;
        left: -4px;

        #right,
        #bottom {
          background-color: ${(props) => props.theme.colors.logoBlueLight};
          opacity: 0.8;
        }
      }
      h3 {
        color: ${(props) => props.theme.colors.logoBlue};
        font-family: ${(props) => props.theme.fonts.heading};
        line-height: 0;
        font-size: 1.8rem;
        margin-left: 2px;
        align-self: center;
      }
    }
    .pink {
      .wrapper {
        .bar {
          background-color: ${(props) => props.theme.colors.logoPink};
        }
        .left {
          background-color: ${(props) => props.theme.colors.logoPink};
          transform: translateX(8px);
          width: 8px;
          height: 45px;
        }
        .top {
          background-color: ${(props) => props.theme.colors.logoPink};
          width: 45px;
          height: 8px;
          border-bottom-left-radius: 0;
        }
      }
      .duplicate {
        top: -12px;
        left: -4px;

        #right,
        #bottom {
          background-color: ${(props) => props.theme.colors.logoPinkLight};
          opacity: 0.8;
        }
      }
      H3 {
        color: ${(props) => props.theme.colors.logoPink};
        font-family: ${(props) => props.theme.fonts.heading};
        line-height: 0;
        font-size: 1.8rem;
        margin-left: 2px;
        align-self: center;
      }
    }
    @media only screen and (max-width: 1040px) {
      .yellow,
      .blue,
      .pink {
        .wrapper {
          .left {
            transform: translateX(5px);
            width: 5px;
            height: 35px;
          }
          .top {
            width: 35px;
            height: 5px;
          }
        }
        .duplicate {
          top: -9px;
          left: -4px;
        }
        span {
          margin-left: 0px;
        }
      }
    }
    .white {
      .wrapper {
        .bar {
          background-color: ${(props) => props.theme.colors.logoWhite};
        }
        .left {
          background-color: ${(props) => props.theme.colors.logoWhite};
          transform: translateX(8px);
          width: 8px;
          height: 45px;
        }
        .top {
          background-color: ${(props) => props.theme.colors.logoWhite};
          width: 45px;
          height: 8px;
          border-bottom-left-radius: 0;
        }
      }
      .duplicate {
        top: -12px;
        left: -4px;

        #right,
        #bottom {
          background-color: ${(props) => props.theme.colors.white};
          opacity: 0.8;
        }
      }
      span {
        color: ${(props) => props.theme.colors.logoWhite};
        line-height: 0;
        font-size: 1.8rem;
        margin-left: 2px;
        align-self: center;
      }
    }
    a {
      ${onHoverFocus(() => {
        return css`
          ${hoverScaleDown};
          outline: none;
        `;
      })};
    }
  }
`;
