import styled, { css } from "styled-components";

import { flex, displayFlex, backgroundImageProperties } from "../Global/style-variables";
import rocketGlitter from "../../resources/images/square-glitter.png";

export const HeaderWrapper = styled.header`
  background-color: ${(props) => props.theme.colors.mainBG};
  position: relative;
  padding: 0 6rem;
  overflow: hidden;
  z-index: 1;

  ${(props) =>
    props.glitterOn &&
    css`
      &:after {
        position: absolute;
        content: "";
        ${backgroundImageProperties};
        background-image: url(${rocketGlitter});
        top: -135px;
        right: -15px;
        height: 280px;
        width: 135px;
        z-index: -1;
      }

      @media only screen and (max-width: 1000px) {
        &:after {
          top: -100px;
          right: -35px;
        }
      }

      @media only screen and (max-width: 810px) {
        padding: 0 3rem;

        &:after {
          height: 210px;
          width: 105px;
          top: 5px;
        }
      }
      @media only screen and (max-width: 600px) {
        &:after {
          top: 35px;
          right: -55px;
        }
      }
      @media only screen and (max-width: 690px) {
        &:after {
          top: 215px;
        }
      }
      @media only screen and (max-width: 530px) {
        &:after {
          top: 295px;
        }
      }
      @media only screen and (max-width: 440px) {
        padding: 0 1rem;

        &:after {
          top: 325px;
          right: -30px;
        }
      }
      @media only screen and (max-width: 375px) {
        &:after {
          top: 385px;
          right: -40px;
          height: 180px;
          width: 90px;
        }
      }
    `}

  .inner-wrapper {
    ${displayFlex("space-between", "center")};
    padding: 2rem 0;
    max-width: 1280px;
    margin: 0 auto;

    .col-2 {
      ${flex("1 auto")};
      ${displayFlex("flex-end", "unset")};
    }
  }
`;
