import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

import Logo from "../Logo";
import { NavWrapper } from "./style";
import { master } from "../../resources";

const Navigation = () => {
  useEffect(() => {
    const headerHeight = document.querySelector("header").offsetHeight;

    window.addEventListener("scroll", (e) => {
      const body = document.querySelector("body");
      const scrollingElement = e.target.scrollingElement || e.target.documentElement;
      const hasClass = body.classList.contains("scroll-active");

      if (scrollingElement && scrollingElement.scrollTop > headerHeight) {
        if (!hasClass) {
          body.classList.add("scroll-active");
          body.style.cssText = `padding-top: ${headerHeight}px;`;
        }
      } else {
        body.classList.remove("scroll-active");
        body.style.cssText = `padding-top: 0;`;
      }
    });
  });

  // Using the "useState" instead of the contructor to store the states -> this prevents the need to use a class component
  // Also it makes the code shorter
  const [showMenu, setMenuState] = useState(false);

  const openMenu = () => {
    document.querySelector("body").classList.add("active-mobile-menu");
    setMenuState(true);
  };

  const closeMenu = () => {
    document.querySelector("body").classList.remove("active-mobile-menu");
    setMenuState(false);
  };

  const { menuItems } = master;
  const menuArray = Object.entries(menuItems);

  return (
    <NavWrapper>
      {/* Desktop Menu */}
      <nav>
        <ul id="header-menu">
          {menuItems.defaultMenu.map((menuItem, menuItemIndex) => {
            const { text, link } = menuItem;

            return (
              <li key={menuItemIndex}>
                <Link to={`${link}`}>{text}</Link>
              </li>
            );
          })}
        </ul>
      </nav>

      {/* For the open menu button */}
      <button className="hamburger-button" onClick={() => openMenu()}>
        <i className="custom-icon icon-menu">&#xe805;</i>
      </button>

      {/* For the active mobile menu */}
      {showMenu && (
        <div id="mobile-menu">
          <div className="mm-list-inner">
            {/* For the close button */}
            <button id="close-button" onClick={() => closeMenu()}>
              <i className="custom-icon icon-close">&#xe814;</i>
            </button>

            {/* For the logo wrapper */}
            <div className="logo-wrapper">
              <Logo linkTo="/" logoClass="white" text="madebyHaT" />
            </div>

            <div className="blocks-wrapper">
              {/* Loop through the "menuArray" and output for "Menu" and "Legal" */}
              {menuArray.map((menuItem, menuTypeIndex) => {
                const [name, innerArray] = menuItem;
                const menuItemName = name === "defaultMenu" ? "menu" : "legal";
                const nameCapitilized = menuItemName.charAt(0) + menuItemName.slice(1);

                return (
                  <div key={menuTypeIndex} className={`block ${menuItemName}-group`}>
                    {/* For the heading */}
                    <h2>{nameCapitilized}</h2>

                    {/* For the listing */}
                    <nav>
                      <ul className={`list-wrapper ${menuItemName}-block`}>
                        {innerArray.map((menuItem, menuItemIndex) => {
                          const { text, link } = menuItem;

                          return (
                            <li key={menuItemIndex}>
                              <Link to={`${link}`} onClick={() => closeMenu()}>
                                {text}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </nav>
                  </div>
                );
              })}

              {/* For the contact group */}
              <div className="block contact-group">
                {/* For the heading */}
                <h2>Contact</h2>

                {/* For the list wrapper */}
                <ul className="list-wrapper contact-block">
                  <li>
                    <a href="tel:+610412834402" className="mm-link-pink">
                      <i className="custom-icon icon-phone">&#xe803;</i>
                      <span>+61 0412 834 402</span>
                    </a>
                  </li>
                  <li>
                    <a href="mailto:madebyhat@gmail.com" className="mm-link-pink">
                      <i className="custom-icon icon-mail">&#xf0e0;</i>
                      <span>madebyhat@gmail.com</span>
                    </a>
                  </li>
                </ul>
              </div>

              {/* For the exit menu */}
              <button id="exit-mobile-menu" aria-label="press to exit mobile menu" onClick={() => closeMenu()}>
                Exit menu
              </button>
            </div>
          </div>
        </div>
      )}
    </NavWrapper>
  );
};

export default Navigation;
