import styled, { css } from "styled-components";

import { flex, displayFlex, hoverTransition, onHoverFocus } from "../Global/style-variables";

export const FooterWrapper = styled.footer`
  ${displayFlex("unset", "unset")};
  position: relative;
  flex-direction: column;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.mainBlue};
  padding: 0 6rem;

  .inner-wrapper {
    ${displayFlex("flex-start", "flex-start")};
    padding: 5rem 0 2rem 0;
    max-width: 1280px;
    margin: 0 auto;

    .logo-wrapper {
      ${flex("0 0 400px")};

      .about-wrapper {
        position: relative;
        margin-top: 40px;

        &:before {
          position: absolute;
          content: "";
          width: 70px;
          height: 2px;
          top: -20px;
          left: 0;
          border-top: 2px solid ${(props) => props.theme.colors.logoPurpleLight};
        }
        p {
          padding-right: 70px;
        }
      }
    }

    .blocks-wrapper {
      ${flex("1")};
      ${displayFlex("space-between", "flex-start")};

      .block {
        ${flex("1 0 30%")};
        display: inline-block;
        padding-left: 10px;

        /* For the heading and listing */
        h2,
        .list-wrapper {
          padding-left: 1rem;
        }

        /* For the heading */
        h2 {
          font-family: ${(props) => props.theme.fonts.heading};
          font-size: 1.5rem;
          line-height: 0;
          padding-bottom: 1rem;
        }

        /* For the list wrapper */
        .list-wrapper li {
          padding-bottom: 0.7rem;

          a {
            ${hoverTransition};
            color: ${(props) => props.theme.colors.white};
            font-size: 1rem;
            border-radius: 4px;
            line-height: 2;
            padding: 0.4rem 0.5rem;
          }
        }

        /* For the menu group */
        &.menu-group {
          border-left: 2px solid ${(props) => props.theme.colors.logoYellow};

          .menu-block {
            display: flex;
            flex-flow: row wrap;

            > li {
              width: 80px;
              height: 40px;
              text-align: left;

              a {
                ${onHoverFocus(() => {
                  return css`
                    color: ${(props) => props.theme.colors.mainBlue};
                    background-color: ${(props) => props.theme.colors.logoYellow};
                  `;
                })}
              }
            }
          }
        }

        /* For the legal group */
        &.legal-group {
          border-left: 2px solid ${(props) => props.theme.colors.logoBlue};

          a {
            ${onHoverFocus(() => {
              return css`
                color: ${(props) => props.theme.colors.white};
                background-color: ${(props) => props.theme.colors.logoBlue};
              `;
            })}
          }
        }

        /* For the contact group */
        &.contact-group {
          border-left: 2px solid ${(props) => props.theme.colors.logoPink};

          .contact-block li {
            a {
              display: inline-flex;
              color: white;
              line-height: 1rem;

              .custom-icon {
                ${displayFlex("unset", "unset")};
                font-size: 1.4rem;
                text-align: center;
                width: 33px;
              }

              ${onHoverFocus(() => {
                return css`
                  color: ${(props) => props.theme.colors.white};
                  background-color: ${(props) => props.theme.colors.logoPink};
                `;
              })}
            }
          }
        }
      }

      @media (max-width: 645px) {
        flex-direction: column;

        .block {
          margin-bottom: 0.5rem;
        }
      }
    }

    @media (max-width: 1315px) {
      flex-direction: column;
      width: 100%;
      align-items: center;
      padding-top: 3rem;
      padding-bottom: 0;

      .logo-wrapper {
        ${flex("auto")};
        width: 100%;
        margin-bottom: 2rem;
      }

      .blocks-wrapper {
        ${flex("auto")};
        width: 100%;

        .block {
          width: 100%;
        }
      }
    }
  }

  .bottom-footer {
    ${flex("1 auto")};
    padding-bottom: 2rem;
    align-self: flex-end;
  }

  @media (max-width: 810px) {
    padding: 0 3rem;

    .inner-wrapper .blocks-wrapper .block {
      ${flex("1")};
    }
  }
  @media (max-width: 440px) {
    padding: 0 1rem;
  }
`;
