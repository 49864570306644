import React from "react";
import { Link } from "gatsby";

import { master } from "../../resources";
import Logo from "../Logo";
import { FooterWrapper } from "./style";

const Footer = () => {
  const { menuItems } = master;
  const menuArray = Object.entries(menuItems);

  return (
    <FooterWrapper>
      <div className="inner-wrapper">
        <div className="logo-wrapper">
          <Logo linkTo="/" logoClass="white" text="madebyHaT" />
          <div className="about-wrapper">
            <p>We develop web products that perform the best to maximise its online presence.</p>
          </div>
        </div>

        <div className="blocks-wrapper">
          {/* Loop through the "menuArray" and output for "Menu" and "Legal" */}
          {menuArray.map((menuItem, menuTypeIndex) => {
            const [name, innerArray] = menuItem;
            const menuItemName = name === "defaultMenu" ? "menu" : "legal";
            const nameCapitilized = menuItemName.charAt(0) + menuItemName.slice(1);

            return (
              <div key={menuTypeIndex} className={`block ${menuItemName}-group`}>
                {/* For the heading */}
                <h2>{nameCapitilized}</h2>

                {/* For the listing */}
                <nav>
                  <ul className={`list-wrapper ${menuItemName}-block`}>
                    {innerArray.map((menuItem, menuItemIndex) => {
                      const { text, link } = menuItem;

                      return (
                        <li key={menuItemIndex}>
                          <Link to={`${link}`}>{text}</Link>
                        </li>
                      );
                    })}
                  </ul>
                </nav>
              </div>
            );
          })}

          {/* For the contact group */}
          <div className="block contact-group">
            {/* For the heading */}
            <h2>Contact</h2>

            {/* For the list wrapper */}
            <ul className="list-wrapper contact-block">
              <li>
                <a href="tel:+610412834402" className="mm-link-pink">
                  <i className="custom-icon icon-phone">&#xe803;</i>
                  <span>+61 0412 834 402</span>
                </a>
              </li>
              <li>
                <a href="mailto:madebyhat@gmail.com" className="mm-link-pink">
                  <i className="custom-icon icon-mail">&#xf0e0;</i>
                  <span>madebyhat@gmail.com</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Bottom Footer */}
      <div className="bottom-footer">
        <div className="bottom-inner">
          <span>© {new Date().getFullYear()} madebyHaT</span>
        </div>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
