import ftlPortfolio from "./images/ftl-web-portfolio.png";
import wheatgrassPortfolio from "./images/wheatgrass-web-portfolio.png";

export const master = {
  menuItems: {
    defaultMenu: [
      {
        text: "Services",
        link: "/#services",
      },
      {
        text: "About",
        link: "/#about",
      },
      {
        text: "Portfolio",
        link: "/#portfolio",
      },
      {
        text: "Contact",
        link: "/#contact",
      },
    ],
    legal: [
      {
        text: "Terms & Condition",
        link: "/terms",
      },
      {
        text: "Privacy",
        link: "/privacy",
      },
    ],
  },
  portfolioArray: [
    {
      companyName: "Flexi Tennis Leagues",
      companyBrief: "Amateur tennis leagues management app & website",
      summaryPoints: [
        {
          point: "Full new application and website design and development project",
        },
        {
          point: "Over 100+ users within 2 months of its launch",
        },
        {
          point: "High speed site performance with bounce rate of 9%",
        },
      ],
      quote:
        "We absolutely love our app and website! madebyHaT did such an amazing job and enables us to approach our clients all across Australia effectively.",
      title: "CEO",
      name: "Henry",
      alt: "ftl-portfolio",
      image: `${ftlPortfolio}`,
    },
    {
      companyName: "Wheatgrass Nutrients",
      companyBrief: "A web app that aims to educate on the benefits of drinking wheatgrass.",
      summaryPoints: [
        {
          point: "Full new website design and development project",
        },
        {
          point: "100% Accessibility, Best practices, and SEO score on Google LightHouse",
        },
        {
          point: "Low cost with a simple CMS for content population",
        },
      ],
      quote:
        "It was great working with the team. They are very approachable and included me through the whole process. Really happy with the website!",
      title: "Health educator",
      name: "Niki",
      alt: "wheatgrass-nutrients",
      image: `${wheatgrassPortfolio}`,
    },
  ],
  contactEnquiryType: ["general-enquiry", "website", "website-revamping", "new-website"],
};
