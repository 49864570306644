import React, { Fragment } from "react";
import { HeaderWrapper } from "./style";
import Navigation from "../Navigation";
import Logo from "../Logo";
import { globalHistory } from "@reach/router";

const Header = () => {
  const currentUrl = globalHistory.location.pathname;

  return (
    <Fragment>
      <HeaderWrapper glitterOn={currentUrl === "/"}>
        <div className="inner-wrapper">
          <div className="col col-1">
            <div className="col-inner">
              <Logo linkTo="/" logoClass="basic" text="madebyHaT" />
            </div>
          </div>

          <div className="col col-2">
            <div className="col-inner">
              <Navigation />
            </div>
          </div>
        </div>
      </HeaderWrapper>
    </Fragment>
  );
};

export default Header;
