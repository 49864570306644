import styled, { css } from "styled-components";

import {
  displayFlex,
  backgroundImageProperties,
  hoverTransition,
  hoverScaleDown,
  buttonReset,
  onHoverFocus,
} from "../Global/style-variables";
import siteLogo from "../../resources/images/madebyhat-logo-transparent.png";

export const NavWrapper = styled.div`
  #header-menu {
    ${displayFlex("space-between", "center")};
    width: 100%;

    li {
      margin: 0 1.7rem;

      a {
        ${hoverTransition};
        font-size: 1.2rem;
        border-radius: 4px;
        line-height: 2;
        padding: 0.4rem 0.5rem;

        ${onHoverFocus(() => {
          return css`
            color: ${(props) => props.theme.colors.white};
            background-color: ${(props) => props.theme.colors.mainBlue};
          `;
        })}
      }

      &:last-child a {
        ${hoverTransition};
        color: white;
        border: 2px solid ${(props) => props.theme.colors.mainBlue};
        background-color: ${(props) => props.theme.colors.mainBlue};
        padding: 1rem;

        ${onHoverFocus(() => {
          return css`
            color: ${(props) => props.theme.colors.mainBlue};
            background-color: transparent;
          `;
        })}
      }
    }
  }

  // Initially hiding the hamburger and mobile menu
  .hamburger-button,
  #mobile-menu {
    display: none;
  }

  .hamburger-button {
    ${hoverTransition};
    ${buttonReset};
    width: 100%;

    .icon-menu {
      font-size: 4rem;
    }

    ${onHoverFocus(() => {
      return css`
        ${hoverScaleDown};
        outline: none;
      `;
    })};
  }

  // For the mobile menu
  #mobile-menu {
    .mm-list-inner {
      display: block;
      background-color: ${(props) => props.theme.colors.mainBlue};
      color: ${(props) => props.theme.colors.white};
      min-height: 100%;
      width: 310px;
      position: fixed;
      z-index: 1;
      top: 0;
      right: 0;
      overflow-x: hidden;
      padding: 60px 20px 0 20px;
      box-shadow: 0 0 2px rgba(255, 255, 255, 0.4);

      #close-button {
        ${buttonReset};
        ${hoverTransition};
        background-color: ${(props) => props.theme.colors.mainBlue};
        color: ${(props) => props.theme.colors.white};
        position: absolute;
        top: 30px;
        right: 30px;
        border: 1px solid white;
        border-radius: 50px;
        width: 40px;
        height: 40px;

        .icon-close {
          font-size: 1.5rem;
        }

        ${onHoverFocus(() => {
          return css`
            background-color: ${(props) => props.theme.colors.white};
            color: ${(props) => props.theme.colors.mainBlue};
            outline: none;
          `;
        })};
      }

      .logo-wrapper {
        max-width: 180px;
        width: 100%;
        margin: 25px 0;

        .logo-link {
          ${hoverTransition};
          ${displayFlex("flex-start", "center")};
          width: 100%;

          #mm-logo {
            ${backgroundImageProperties}
            ${hoverTransition};
            background-image: url(${siteLogo});
            width: 65px;
            height: 65px;
          }
          span {
            color: ${(props) => props.theme.colors.white};
            font-size: 2rem;
          }

          ${onHoverFocus(() => {
            return css`
              ${hoverScaleDown}
              outline: none;
            `;
          })};
        }
      }

      .blocks-wrapper {
        display: flex;
        flex-direction: column;
        padding-left: 15px;
        margin-bottom: 8rem;
        overflow-x: hidden;
        overflow-y: auto;
        scroll-behavior: smooth;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        height: 80vh;

        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
          display: none;
        }

        .block {
          display: inline-block;
          padding-left: 10px;
          margin-bottom: 30px;

          /* For the heading and listing */
          h2,
          .list-wrapper {
            padding-left: 2rem;
          }

          /* For the heading */
          h2 {
            font-family: ${(props) => props.theme.fonts.heading};
            font-size: 1.8rem;
            line-height: 0;
            padding-bottom: 1rem;
          }

          /* For the list wrapper */
          .list-wrapper li {
            padding-bottom: 0.7rem;

            a {
              ${hoverTransition};
              color: ${(props) => props.theme.colors.white};
              font-size: 1.2rem;
              border-radius: 4px;
              line-height: 2;
              padding: 0.4rem 0.5rem;
            }
          }

          /* For the menu group */
          &.menu-group {
            border-left: 2px solid ${(props) => props.theme.colors.logoYellow};

            a {
              ${onHoverFocus(() => {
                return css`
                  color: ${(props) => props.theme.colors.mainBlue};
                  background-color: ${(props) => props.theme.colors.logoYellow};
                `;
              })}
            }
          }

          /* For the legal group */
          &.legal-group {
            border-left: 2px solid ${(props) => props.theme.colors.logoBlue};

            a {
              ${onHoverFocus(() => {
                return css`
                  color: ${(props) => props.theme.colors.white};
                  background-color: ${(props) => props.theme.colors.logoBlue};
                `;
              })}
            }
          }
        }

        /* For the contact group */
        .contact-group {
          border-left: 2px solid ${(props) => props.theme.colors.logoPink};

          .contact-block li {
            a {
              display: inline-flex;
              color: white;
              line-height: 1rem;

              .custom-icon {
                ${displayFlex("unset", "unset")};
                font-size: 1.6rem;
                text-align: center;
                width: 33px;
              }

              ${onHoverFocus(() => {
                return css`
                  color: ${(props) => props.theme.colors.white};
                  background-color: ${(props) => props.theme.colors.logoPink};
                `;
              })}
            }
          }
        }

        /* For the exit menu button */
        #exit-mobile-menu {
          ${hoverTransition};
          font-size: 1.5rem;
          border: none;
          border-radius: 4px;
          padding: 0.8rem;
          margin-top: 2rem;
          transform: translateX(100%);
          opacity: 0;
          outline: none;

          &:focus {
            transform: translateX(0);
            opacity: 1;
          }
        }
      }

      @media only screen and (max-width: 660px) {
        left: 0;
        width: auto;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    #header-menu {
      display: none;
    }

    .hamburger-button,
    #mobile-menu {
      display: block;
    }
  }

  @media only screen and (max-width: 500px) {
    #mobile-menu {
      .mm-list-inner {
        #close-button {
          width: 30px;
          height: 30px;

          .icon-close {
            font-size: 1rem;
          }
        }

        .logo-wrapper {
          .logo-link {
            #mm-logo {
              width: 55px;
              height: 55px;
            }
          }
        }
      }
    }
  }
`;
